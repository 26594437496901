section.loginForm {
    width: 100%;
    height: 100%;

    &:before {
        z-index: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
        background-image: url('./../img/bgHome.jpg');
        background-size: cover;
        background-position: center center;
        opacity: .5;
    }

    div.containerLogin {
        max-width: 600px;
        margin: 0 auto;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        >div {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
    }

}