main.layout
{
    max-width: 1600px;
    margin: 0 auto;
    padding: 15px;

    &:before{
        content:"";
        display:block;
        width:100%;
        height: 200px;
        background-image: url('./../img/bgHome.jpg');
        background-size: cover;
        background-position: center center;
        border-radius: 6px 6px 0 0;
    }
    
}